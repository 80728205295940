// -----------------------------
// グローバルな変数はここで定義しておく
// -----------------------------

// -----------------------------
// 関数群
// -----------------------------

// アコーディオンパネル
const setUpAccordion = () => {
  const details = document.querySelectorAll(".js-details");
  const isRunning = "running"; // アニメーション実行中のときに付与する予定のカスタムデータ属性の値
  const isOpend = "is-opened"; // アイコン操作用のクラス名

  details.forEach((element) => {
    const summary = element.querySelector(".js-summary");
    const content = element.querySelector(".js-content");

    summary.addEventListener("click", (event) => {
      event.preventDefault();
      if (element.dataset.animStatus === isRunning) {
        return;
      }
      if (element.open) {
        element.classList.toggle(isOpend);
        const closingAnim = content.animate(
          closingAnimKeyframes(content),
          animTiming
        );

        element.dataset.animStatus = isRunning;
        closingAnim.onfinish = () => {
          element.removeAttribute("open");
          element.dataset.animStatus = "";
        };
      } else {
        element.setAttribute("open", "true");
        element.classList.toggle(isOpend);

        const openingAnim = content.animate(
          openingAnimKeyframes(content),
          animTiming
        );
        element.dataset.animStatus = isRunning;
        openingAnim.onfinish = () => {
          element.dataset.animStatus = "";
        };
      }
    });
  });
};

/* アニメーションの時間とイージング */
const animTiming = {
  duration: 400,
  easing: "ease-out",
};

/*　アコーディオンを閉じるときのキーフレーム　*/
const closingAnimKeyframes = (content) => [
  {
    height: content.offsetHeight + "px",
    opacity: 1,
  },
  {
    height: 0,
    opacity: 0,
  },
];

/* アコーディオンを開くときのキーフレーム */
const openingAnimKeyframes = (content) => [
  {
    height: 0,
    opacity: 0,
  },
  {
    height: content.offsetHeight + "px",
    opacity: 1,
  },
];

// -----------------------------
// イベント系
// -----------------------------

// DOM読み込まれたら発火
window.addEventListener("DOMContentLoaded", function () {
  setUpAccordion();
});

// リサイズ時に発火
window.addEventListener("resize", function () {});

// スクロール時に発火
window.addEventListener("scroll", function () {});

// -----------------------------
// その他
// -----------------------------

//スクロールでアニメーションクラスを追加
if (document.querySelector(".anm") != null) {
  const anm = document.querySelectorAll(".anm");
  const options = {
    root: null,
    rootMargin: "-4%",
    threshold: [0],
  };
  const observe = new IntersectionObserver(addAnimation, options);
  anm.forEach(function (elem, index) {
    observe.observe(elem);
  });
  function addAnimation(entries) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        entry.target.classList.add("is-show");
      } else {
        // entry.target.classList.remove("is-show");
      }
    });
  }
}
